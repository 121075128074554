import { func, number, oneOfType, string } from 'prop-types'
import markerPin from '../assets/marker-pin.png';
import React from 'react'

const Marker = ({ userName, className, lat, lng, markerId, onClick, ...props } : any) => {
	// console.log("Marker: ", lat, lng);
	
	return (
		<div>
			<img
				className={className}
				src={markerPin}
				lat={lat.toString()}
				lng={lng.toString()}
				onClick={(e) => (onClick ? onClick(e, { markerId, lat, lng }) : null)}
				style={{ cursor: 'pointer', fontSize: 40, width: "40px", height: "40px", marginLeft: "-20px", marginTop: "-45px"}}
				alt={markerId}
				{...props}
			/>

			<p>{userName}</p>
		</div>
	)
}

// Marker.propTypes = {
// 	className: string,
// 	/**
// 	 * The id of the marker.
// 	 */
// 	markerId: oneOfType([number, string]).isRequired,
// 	/**
// 	 * The latitude of the marker.
// 	 */
// 	lat: number.isRequired,
// 	/**
// 	 * The longitude of the marker.
// 	 */
// 	lng: number.isRequired,
// 	/**
// 	 * The function to call when the marker is clicked.
// 	 */
// 	onClick: func,
// }

export default Marker