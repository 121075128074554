import React from "react"

function Footer() {
    return (
        <div className="footer">
            <p> Copyright Locstat Inc @ 2023</p>
        </div>
    )
}

export default Footer