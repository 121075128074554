import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
  useLocation,
} from "react-router-dom";
import Header from './components/Header';
import ErrorPage from './Pages/ErrorPage';
import UsersPage from './Pages/UserPage';
import RealtimePage from './Pages/RealtimePage';
import Footer from './components/Footer';
import React, { useEffect } from 'react';
import LoginPage from './Pages/LoginPage';
import { useAuth } from './contexts/AuthContext';
import HomePage from './Pages/HomePage';


function ProtectedRoute({ children }: any) {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.user) {
      return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

function AppLayout() {
  useEffect(() => {
    document.title = 'Locstat Console';
  }, [])

  return (
    <>
    <ProtectedRoute>
      <Header />
      <Content>
      <div className="content-container">
        <Outlet />
      </div>
      </Content>
      <Footer />
    </ProtectedRoute>
    </>
  )
}

function Content(props: any) {
  return (
    <div className="content">
      {props.children}
    </div>
  )
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <HomePage />,
      },
      {
        path: '/users',
        element: <UsersPage />,
      },
      {
        path: '/realtime',
        element: <RealtimePage />,
      },
    ]
  },
  {
    path: "/login",
    element: <LoginPage />
  }
]);

export default router