import firebase, { initializeApp } from 'firebase/app';
import 'firebase/auth';
import { getAuth } from 'firebase/auth';

// Initialize Firebase
const firebaseConfig = {
  apiKey: 'AIzaSyCx-VpcmkHLhJNwdxeiIwjhCEU498myzak',
  authDomain: 'galaxy5locstat.firebaseapp.com',
  databaseURL: 'https://galaxy5locstat-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'galaxy5locstat',
  storageBucket: 'galaxy5locstat.appspot.com',
  messagingSenderId: 'sender-id',
  appId: 'app-id',
  measurementId: 'G-measurement-id',
};

initializeApp(firebaseConfig);
const auth = getAuth()

export default auth;