import { getAuth, signOut } from "firebase/auth";
import React from "react"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../contexts/AuthContext";

export default function LoginSection() {
    let navigate = useNavigate()
    let auth = useAuth()

    const handleLogout = () => {               
        signOut(getAuth()).then(() => {
            navigate("/");
            console.log("Signed out successfully")
        }).catch((error) => {
        });
    }

    return (
        <ul style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-end"
        }}>
            <li>
                <img className="table-avatar" src={(auth.user &&  auth.user.providerData[0].photoURL) ? auth.user.providerData[0].photoURL : require("../assets/avatar.png")}/>
            </li>

            <li>
                <a style={{cursor: "pointer"}} onClick={handleLogout}>Sign Out</a>
            </li>
        </ul>
    )
}