import React, { useEffect, useRef, useState } from "react";
import GoogleMapReact from 'google-map-react';
import GoogleMap from 'google-maps-react-markers'
import mapOptions from './map-options.json'
import Marker from './marker'
import "./mapstyle.css"

const apiKey = 'AIzaSyDeCvTgsqDaWbgQshlIk-Ww_EiAqJ7yED8'

const coordinates = [
	[
		{
			lat: 45.4046987,
			lng: 12.2472504,
			name: 'Venice',
		},
		{
			lat: 41.9102415,
			lng: 12.3959151,
			name: 'Rome',
		},
		{
			lat: 45.4628328,
			lng: 9.1076927,
			name: 'Milan',
		},
	],
	[
		{
			lat: 40.8518,
			lng: 14.2681,
			name: 'Naples',
		},
		{
			lat: 43.7696,
			lng: 11.2558,
			name: 'Florence',
		},
		{
			lat: 37.5023,
			lng: 15.0873,
			name: 'Catania',
		},
	],
]


export default function SimpleMap(props : any){
    const mapRef = useRef(null)
	const [mapReady, setMapReady] = useState(false)
	const [mapBounds, setMapBounds] = useState({})
	const [usedCoordinates, setUsedCoordinates] = useState(0)
	const [currCoordinates, setCurrCoordinates] = useState(coordinates[usedCoordinates])
	const [highlighted, setHighlighted] = useState(null)
	const [clients, setClients] = useState([])

	/**
	 * @description This function is called when the map is ready
	 * @param {Object} map - reference to the map instance
	 * @param {Object} maps - reference to the maps library
	 */
	// eslint-disable-next-line no-unused-vars
	const onGoogleApiLoaded = ({ map, maps }: any) => {
		mapRef.current = map
		setMapReady(true)
	}

	// eslint-disable-next-line no-unused-vars
	const onMarkerClick = (e: any, { markerId, lat, lng }: any) => {
		setHighlighted(markerId)
	}

	const onMapChange = ({ bounds, zoom }: any) => {
		const ne = bounds.getNorthEast()
		const sw = bounds.getSouthWest()
		/**
		 * useSupercluster accepts bounds in the form of [westLng, southLat, eastLng, northLat]
		 * const { clusters, supercluster } = useSupercluster({
		 *	points: points,
		 *	bounds: mapBounds.bounds,
		 *	zoom: mapBounds.zoom,
		 * })
		 */
		setMapBounds({ ...mapBounds, bounds: [sw.lng(), sw.lat(), ne.lng(), ne.lat()], zoom })
		setHighlighted(null)
	}

	const updateCoordinates = () => setUsedCoordinates(!usedCoordinates ? 1 : 0)

	useEffect(() => {
		setCurrCoordinates(coordinates[usedCoordinates])
	}, [usedCoordinates])

	useEffect(() => {
		setClients(props.clients)
	}, [props])

	currCoordinates.map((coord: any, index : number) => {
	})

	const renderClientMarkers = () => {
		return clients.map(({ location, name }: any, index: number) => {
			// console.log(location);
			
			if(location) {
				return (<Marker
					userName={name}
					key={index}
					lat={location.latitude}
					lng={location.longitude}
					markerId={name}
					onClick={onMarkerClick}
					className="marker"
				/>)
			}
		})
	}

  return (
    <>
      <GoogleMap
					apiKey={apiKey}
					defaultCenter={{ lat: 45.4046987, lng: 12.2472504 }}
					defaultZoom={0}
					options={mapOptions}
					mapMinHeight="600px"
					onGoogleApiLoaded={onGoogleApiLoaded}
					onChange={onMapChange}
				>
					{renderClientMarkers()}
				</GoogleMap>

      {highlighted && (
					<div className="highlighted">
						{highlighted}{' '}
						<button type="button" onClick={() => setHighlighted(null)}>
							X
						</button>
					</div>
      )}
    </>
  )
}