import { getAuth } from 'firebase/auth';
import React, { createContext, useContext, useState, useEffect } from 'react';

type AuthContextType = {
    value?: any,
    user?: any,
    accessToken?: string,
    login?: any,
    logout?: any
}

const AuthContext = createContext<AuthContextType>({});

export function useAuth() {
  return useContext(AuthContext);
}

export default function AuthProvider({ children } : any) {
  const [user, setUser] = useState<any>(null); // This will hold the authenticated user
  const [accessToken, setAccessToken] = useState<any>("")

  // You can set up useEffect to check the user's authentication status here
  useEffect(() => {
    getAuth().onAuthStateChanged(async user => {
        setUser(user);
        let token = await user?.getIdToken();
        setAccessToken(token)
    })
  }, []);

  // Your login and logout functions
  const login = (userLoggedIn: any) => {
    setUser(userLoggedIn)
  };

  const logout = () => {
  };

  return (
    <AuthContext.Provider value={{ user, accessToken, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}