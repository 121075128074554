import React, { useEffect } from "react"
import { GoogleLogin, GoogleOAuthProvider, useGoogleOneTapLogin } from '@react-oauth/google';
import jwtDecode from 'jwt-decode';
import { useAuth } from '../contexts/AuthContext';
import auth from '../firebase/firebase'; // Import Firebase auth
import { signInWithPopup, GoogleAuthProvider, getAuth, signInWithCredential } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import BackendService from "../Services/BackendService";
import { toast } from "react-toastify";
import _ from "lodash";

const googleProvider = new GoogleAuthProvider();

function LoginPage(props: any) {

    const { login } = useAuth();
    let navigate = useNavigate();

    useGoogleOneTapLogin({
        onSuccess: credentialResponse => {
            handleLogin(credentialResponse)
        },
        onError: () => {
          console.log('Login Failed');
        },
    });

    useEffect(() => {
        getAuth().onAuthStateChanged(async user => {
            if(user)
            {
                let accessToken = await user?.getIdToken()
                console.log("Get access token: ", accessToken);
                
                if(!_.isEmpty(accessToken))
                {
                    BackendService.checkAdmin(accessToken)
                    .then((data: any) => {
                        if(data.data.role === "1") {
                            console.log("This is admin");
                            navigate("/")
                        }
                        else {
                            console.log("Force signout");
                            getAuth().signOut()
                            toast("Invalid account!", {
                                type: "error"
                            });
                        }
                    })
                }
            }

        })
    }, [])

    const handleLogin = async (credentialResponse: any) => {
        var obj = jwtDecode(credentialResponse.credential);
        if (credentialResponse.credential) {
            console.log(credentialResponse.credential);
            
            signInWithCredential(getAuth(), credentialResponse)
        };
    }

    const loginWithGoogle = async () => {
        const result = await signInWithPopup(getAuth(), googleProvider);
        const user = result.user;
      
        // Get the user's info from Firebase Authentication.
        const userInfo = {
          id: user.uid,
          name: user.displayName,
          email: user.email,
          photoURL: user.photoURL,
        };
        return userInfo;
      };

    return (
        <>
            <div className="login-form">
                <img className="login-icon" src={require("../assets/avatar.png")} />
                <p className="login-title">Locstat Console</p>
                <button onClick={loginWithGoogle}>Google Sign In</button>
            </div>

        </>
    )
}

export default LoginPage

