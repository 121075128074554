import React, { useEffect, useState } from "react";

export default function ListClients(props : any) {
    const [clients, setClients] = useState(props.clients)

    useEffect(() => {
        setClients(props.clients)
        // console.log("On props from parent changed!", props.clients);
    }, [props])

    const renderClients = () => {
        return clients.map((client : any, index : any) => {
            return (
            <div key={index} className="client-avatar" title={client.firstName}>
                <img style={{
                    width: "35px",
                    height: "35px",
                    borderRadius: "50%"
                }} src={client.photoUrl ? client.photoUrl : require("../assets/avatar.png")} alt={""}></img>
                {/* <p key={index}>{JSON.stringify(client)}</p> */}
            </div>
            )
        });
    }

    return (
        <div>
            <p>List clients: {clients.length}</p>
            <div className="list-client-avatar">
                {renderClients()}
            </div>
        </div>
    )
}