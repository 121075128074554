import { NavLink } from "react-router-dom";
import LoginSection from "./LoginSection";
import React from "react";

export default function Header(props: any) {
    return (
        <>
        <div className="top-header">
          <p style={{fontWeight: 200, fontSize: 25}}>Locstat Console</p>
        </div>
        <nav className="header-nav">
            <ul>
                <li>
                    <NavLink to="/" 
                    className={({ isActive }) => 
                      (isActive ? "menu-active" : "inactive-menu")}>
                        Home
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/users" className={({ isActive }) => 
                      (isActive ? "menu-active" : "inactive-menu")}>
                    Users</NavLink>
                </li>
                <li>
                    <NavLink to="/realtime" className={({ isActive }) => 
                      (isActive ? "menu-active" : "inactive-menu")}
                    >Realtimes</NavLink>
                </li>
                <li>
                    <NavLink to="/notifications" className={({ isActive }) => 
                      (isActive ? "menu-active" : "inactive-menu")}
                    >Notifications</NavLink>
                </li>
                <li>
                    <NavLink to="/admins" className={({ isActive }) => 
                      (isActive ? "menu-active" : "inactive-menu")}
                    >Admins</NavLink>
                </li>
                <li>
                    <NavLink to="/server-configs" className={({ isActive }) => 
                      (isActive ? "menu-active" : "inactive-menu")}
                      >Server Configs</NavLink>
                </li>
                <LoginSection />
            </ul>
        </nav>
        </>
    )
}

